import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Loading from '../common/lib/Loading';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import CreditsUtils, { CreditsIndexData } from './lib/CreditsUtils';

interface Props {
    lang: MultiLang;
}

interface State {
    loading: boolean;
    index: CreditsIndexData | null;
}

class CreditsIndex extends Component<Props, State> {

    private isActive: boolean;

    constructor(props: Props) {
        super(props);
        this.isActive = false;
        this.state = {
            loading: true,
            index: null,
        };
    }

    componentDidMount() {
        this.isActive = true;
        this.updatePage();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { lang } = this.props;
        if (prevProps.lang !== lang) {
            this.updatePage();
        }
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    async updatePage() {
        const index = await CreditsUtils.getIndex();
        if (this.isActive) {
            this.setState({ loading: false, index });
        }
    }

    render() {
        const { lang } = this.props;
        if (this.state.loading) {
            return <Loading />;
        }
        if (null === this.state.index) {
            return <PageNotFound lang={lang} />;
        }
        const mtitle = Functions.mlang(this.state.index.name, lang);
        return (
            <>
                <Helmet>
                    <title>{mtitle} - {Functions.siteTitle(lang)}</title>
                </Helmet>
                <h3>{mtitle}</h3>
                <NoticeSiteHasBeenArchived lang={lang} />
                <ul>
                    {this.state.index.pages.map((page) => {
                        const title = Functions.mlang(page.title, lang);
                        const url = CreditsUtils.getPageUrl(page.id);
                        return <li key={page.id}><Link to={url}>{title}</Link></li>;

                    })}
                </ul>
            </>
        );
    }
}
export default CreditsIndex;
