import moment from 'moment';
import XRegExp from 'xregexp';
import Config, { MultiLang } from './config';

const escape = (str: string): string => {
    return encodeURIComponent(str).replace(/[!'()*]/g, (c) => {
        return '%' + c.charCodeAt(0).toString(16);
    });
}

const unescape = (str: string): string => {
    return decodeURIComponent(str);
}

const htmlspecialchars = (str: string): string => {
    return str.replace(/(<|>|&|'|")/g, (match) => {
        switch (match) {
            case '<':
                return '&lt;';
            case '>':
                return '&gt;';
            case '&':
                return '&amp;';
            case '\'':
                return '&#039;';
            case '"':
                return '&quot;';
        }
        return '';
    });
}

const camelCase = (str: string): string => {
    return str.replace(/[-_](.)/g, (...matches) => {
        return matches[1].toUpperCase();
    });
}

const snakeCase = (str: string): string => {
    var camel = camelCase(str);
    return camel.replace(/[A-Z]/g, (...matches) => {
        return "_" + matches[0].charAt(0).toLowerCase();
    });
}

const pascalCase = (str: string): string => {
    var camel = camelCase(str);
    return camel.charAt(0).toUpperCase() + camel.slice(1);
}

const base64Encode = (str: string): string => {
    return btoa(unescape(encodeURIComponent(str)));
}

const base64Decode = (str: string): string => {
    return decodeURIComponent(escape(atob(str)));
}

const formatDate = (timestamp: number, format: string): string => {
    return moment(new Date(timestamp * 1000)).format(format);
}

const ordinal = (num: number): string => {
    const hundred = num % 100;
    if (hundred >= 10 && hundred <= 20) {
        return 'th';
    }
    const ten = num % 10;
    const suffix = ['st', 'nd', 'rd'];
    return ten > 0 && ten < 4 ? suffix[ten - 1] : 'th';
}

const mlang = (str: string, lang: MultiLang): string => {
    const mlLangs = ['en', 'ja'];
    // escape brackets inside of <input type="text" value="...">
    let text = str.replace(/(<input)([^>]*)(>)/isg, (whole, m1, m2, m3) => {
        if (m2.match(/type=["']?(?:text|hidden)["']?/is)) {
            return m1 + m2.replace(/\[/g, '__ml[ml__') + m3;
        }
        return whole;
    });
    // escape brackets inside of <textarea></textarea>
    text = text.replace(/(<textarea[^>]*>)(.*)(<\/textarea>)/isg, (whole, m1, m2, m3) => {
        return m1 + m2.replace(/\[/g, '__ml[ml__') + m3;
    });
    // simple pattern to strip selected lang_tags
    const re = new RegExp('\\[/?(?:[^\\]]+\\|)?' + lang + '(?:\\|[^\\]]+)?\\](?:<br />)?', 'g');
    text = text.replace(re, '');
    // eliminate description between the other language tags.
    mlLangs.forEach((mlLang) => {
        if (mlLang !== lang) {
            const re = XRegExp('\\[(?:[^/][^\\]]+\\|)?' + mlLang + '(?:\\|[^\\]]+)?\\].*?\\[/(?:[^\\]]+\\|)?' + mlLang + '(?:\\|[^\\]]+)?\\](?:<br />)?', 'isg');
            text = text.replace(re, (whole) => {
                return whole.match(/<\/table>/) ? whole : '';
            });
        }
    });
    // unescape brackets inside of <textarea></textarea>
    text = text.replace(/(<textarea[^>]*>)(.*)(<\/textarea>)/isg, (whole, m1, m2, m3) => {
        return m1 + m2.replace(/__ml\[ml__/g, '[') + m3;
    });
    // unescape brackets inside of <input type="text" value="...">
    text = text.replace(/(<input)([^>]*)(>)/isg, (whole, m1, m2, m3) => {
        if (m2.match(/type=["']?(?:text|hidden)["']?/is)) {
            return m1 + m2.replace(/__ml\[ml__/g, '[') + m3;
        }
        return whole;
    });
    return text;
}

const siteTitle = (lang: MultiLang): string => {
    return mlang(Config.SITE_TITLE, lang);
}

const siteSlogan = (lang: MultiLang): string => {
    return mlang(Config.SITE_SLOGAN, lang);
}

const Functions = {
    escape,
    unescape,
    htmlspecialchars,
    camelCase,
    snakeCase,
    pascalCase,
    base64Encode,
    base64Decode,
    formatDate,
    ordinal,
    mlang,
    siteTitle,
    siteSlogan,
};

export default Functions;