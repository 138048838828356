import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import PicoContext, { PicoCategoryData, PicoContentData } from './lib/PicoContext';

interface Props {
    lang: MultiLang;
    context: PicoContext;
    catId: number;
}

const PicoCategory = (props: Props) => {
    const { lang, context, catId } = props;
    const pico = context.getModule();
    if (pico === null) {
        return <PageNotFound lang={lang} />;
    }
    const category = context.getCategory(catId);
    if (category === null) {
        return <PageNotFound lang={lang} />;
    }
    const parentCategories = context.getParentCategories(category.pid);
    const subCategories = context.getSubCategories(catId);
    const contents = context.getCategoryContents(catId);
    return (
        <div className="pico_container">
            <Helmet>
                <title>{Functions.mlang(category.title, lang)} - {Functions.mlang(pico.name, lang)} - {Functions.siteTitle(lang)}</title>
            </Helmet>
            {pico.show_breadcrumbs !== 0 && (
                <div className="pico_breadcrumbs">
                    {parentCategories.map((parentCategory: PicoCategoryData, idx: number) => {
                        return (
                            <Fragment key={parentCategory.id}>
                                {idx > 0 && <>&nbsp;&gt;&nbsp;</>}
                                <Link key={parentCategory.id} to={context.getUrl(parentCategory.link)}>{Functions.mlang(parentCategory.title, lang)}</Link>
                            </Fragment>
                        );
                    })}
                    {parentCategories.length > 0 && <>&nbsp;&gt;&nbsp;</>}
                    {Functions.mlang(category.title, lang)}
                </div>
            )}
            {catId === 0 && pico.message !== '' && (
                <p>{Functions.mlang(pico.message, lang)}</p>
            )}
            <h1>{Functions.mlang(category.title, lang)}</h1>
            {category.desc !== '' && (
                <p>{Functions.mlang(category.desc, lang)}</p>
            )}
            {subCategories.length > 0 && (
                <>
                    <h2>{Functions.mlang('[en]Subcageroies[/en][ja]サブカテゴリー[/ja]', lang)}</h2>
                    {subCategories.map((subCategory: PicoCategoryData) => {
                        return (
                            <dl key={subCategory.id} className="pico_subcategory">
                                <dt><Link to={context.getUrl(subCategory.link)}>{Functions.mlang(subCategory.title, lang)}</Link></dt>
                                <dd>{Functions.mlang(subCategory.desc, lang)}</dd>
                            </dl>
                        );
                    })}
                </>
            )}
            {contents.length > 0 && (
                <>
                    <h2>{Functions.mlang('[en]Contents[/en][ja]コンテンツ[/ja]', lang)}</h2>
                    <ul className="pico_list_contents">
                        {contents.map((content: PicoContentData) => {
                            return <li key={content.id}><Link to={context.getUrl(content.link)}>{Functions.mlang(content.title, lang)}</Link></li>;
                        })}
                    </ul>
                </>
            )}
        </div>
    );
};

export default PicoCategory;