import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AppRoot from './common/AppRoot';

const App: React.FC = () => {
    return (
        <CookiesProvider>
            <BrowserRouter>
                <AppRoot />
            </BrowserRouter >
        </CookiesProvider>
    );
}

export default App;
