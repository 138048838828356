import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps, withRouter } from 'react-router';
import { MultiLang } from '../../config';
import Functions from '../../functions';

interface Props extends RouteComponentProps {
    lang: MultiLang;
}

class PageNotFound extends Component<Props> {

    private url = '/';
    private timer: NodeJS.Timer | null = null;

    goToTopPage() {
        if (this.props.location.pathname !== '/') {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            this.timer = setTimeout(() => {
                this.timer = null;
                this.props.history.push(this.url);
            }, 5000);
        }
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    render() {
        const { lang } = this.props;
        if (process.env.NODE_ENV === 'production') {
            this.goToTopPage();
        }
        return (
            <div>
                <Helmet>
                    <title>Page Not Found - {Functions.siteTitle(lang)}</title>
                </Helmet>
                <h1>Page Not Found</h1>
                <section>
                    <p>The page you were trying to access doesn't exist.</p>
                    <p>If the page does not automatically reload, please click <a href={this.url}>here</a></p>
                </section>
            </div>
        );
    }
}

export default withRouter(PageNotFound);