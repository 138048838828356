import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import PicoContext, { PicoCategoryData, PicoContentData } from './lib/PicoContext';

interface Props {
    lang: MultiLang;
    context: PicoContext;
}

interface PicoMenuCategoryProps {
    lang: MultiLang;
    context: PicoContext;
    category: PicoCategoryData;
    depth: number;
}

const PicoMenuCategory = (props: PicoMenuCategoryProps) => {
    const { lang, context, category, depth } = props;
    const subCategories = context.getSubCategories(category.id);
    const contents = context.getCategoryContents(category.id);
    const level = 'level' + depth;
    const link = <Link to={context.getUrl(category.link)}>{Functions.mlang(category.title, lang)}</Link>;
    let title = <h6>{link}</h6>;
    switch (depth) {
        case 1:
            title = <h1>{link}</h1>;
            break;
        case 2:
            title = <h2>{link}</h2>;
            break;
        case 3:
            title = <h3>{link}</h3>;
            break;
        case 4:
            title = <h4>{link}</h4>;
            break;
        case 5:
            title = <h5>{link}</h5>;
            break;
    }
    return (
        <>
            {title}
            {contents.length > 0 && (
                <div className={level}>
                    <ul className="pico_list_contents_in_menu">
                        {contents.map((content: PicoContentData) => {
                            return <li key={content.id}><Link to={context.getUrl(content.link)}>{Functions.mlang(content.title, lang)}</Link></li>;
                        })}
                    </ul>
                </div>
            )}
            {subCategories.length > 0 && (
                <>
                    {subCategories.map((subCategory: PicoCategoryData) => {
                        return <PicoMenuCategory key={subCategory.id} lang={lang} context={context} category={subCategory} depth={depth + 1} />;
                    })}
                </>
            )}
        </>
    );
}

const PicoMenu = (props: Props) => {
    const { lang, context } = props;
    const pico = context.getModule();
    if (pico === null) {
        return <PageNotFound lang={lang} />;
    }
    const category = context.getCategory(0);
    if (category === null) {
        return <PageNotFound lang={lang} />;
    }
    return (
        <div className="pico_container pico_menu">
            <Helmet>
                <title>{Functions.mlang('[en]Menu[/en][ja]メニュー[/ja]', lang)} - {Functions.mlang(pico.name, lang)} - {Functions.siteTitle(lang)}</title>
            </Helmet>
            {pico.message !== '' && (
                <p>{Functions.mlang(pico.message, lang)}</p>
            )}
            <PicoMenuCategory lang={lang} context={context} category={category} depth={1} />
        </div>
    );
};

export default PicoMenu;