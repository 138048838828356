import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import CreditsIndex from './CreditsIndex';
import CreditsPage from './CreditsPage';

interface Props {
    lang: MultiLang;
}

const Credits = (props: Props) => {
    const { lang } = props;
    const pathname = '/modules/credits';
    return (
        <>
            <Switch>
                <Route exact path={`${pathname}/index.php`} render={(props: RouteComponentProps) => {
                    const { location } = props;
                    const params = new URLSearchParams(location.search);
                    const paramId = params.get('id');
                    if (null === paramId) {
                        return <CreditsIndex lang={lang} />;
                    }
                    if (/^[1-9][0-9]*$/.test(paramId)) {
                        const id = parseInt(paramId, 10);
                        return <CreditsPage lang={lang} id={id} />;
                    }
                    return <PageNotFound lang={lang} />;
                }} />
                <Route exact path={`${pathname}/aboutus.php`} render={() => <CreditsPage lang={lang} id={0} />} />
                <Route exact path={pathname} render={(props: RouteComponentProps) => {
                    const { location } = props;
                    const url = pathname + '/index.php' + location.search + location.hash;
                    return <Redirect to={url} />
                }} />
                <Route component={PageNotFound} />
            </Switch>
        </>
    );
}

export default Credits;
