import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Loading from '../common/lib/Loading';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import PageNotFound from '../common/lib/PageNotFound';
import XoopsCode from '../common/lib/XoopsCode';
import { MultiLang } from '../config';
import Functions from '../functions';
import CreditsUtils, { CreditsIndexData, CreditsPageDetailData } from './lib/CreditsUtils';

interface Props {
    lang: MultiLang;
    id: number;
}

interface State {
    loading: boolean;
    index: CreditsIndexData | null;
    page: CreditsPageDetailData | null;
}

class CreditsPage extends Component<Props, State> {

    private isActive: boolean;

    constructor(props: Props) {
        super(props);
        this.isActive = false;
        this.state = {
            loading: true,
            index: null,
            page: null
        };
    }

    componentDidMount() {
        this.isActive = true;
        this.updatePage();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { lang, id } = this.props;
        if (prevProps.lang !== lang || prevProps.id !== id) {
            this.updatePage();
        }
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    async updatePage() {
        const { id } = this.props;
        const index = await CreditsUtils.getIndex();
        const page = await CreditsUtils.getPage(id);
        if (this.isActive) {
            this.setState({ loading: false, index, page });
        }
    }

    render() {
        const { lang } = this.props;
        if (this.state.loading) {
            return <Loading />;
        }
        if (null === this.state.page || null === this.state.index) {
            return <PageNotFound lang={lang} />;
        }
        const mtitle = Functions.mlang(this.state.index.name, lang);
        const title = Functions.mlang(this.state.page.title, lang);
        const lastupdate = this.state.page.lastupdate === 0 ? '' : Functions.formatDate(this.state.page.lastupdate, 'MMMM Do, YYYY');
        return (
            <>
                <Helmet>
                    <title>{title} - {mtitle} - {Functions.siteTitle(lang)}</title>
                </Helmet>
                <h3>{title}</h3>
                <NoticeSiteHasBeenArchived lang={lang} />
                {'' !== lastupdate && <div style={{ textAlign: 'right' }}>{Functions.mlang('[en]Last Update[/en][ja]最終更新日[/ja]', lang)} : {lastupdate}</div>}
                <hr />
                <XoopsCode lang={lang} text={this.state.page.content} dohtml={true} />
            </>
        );
    }
}
export default CreditsPage;